<template>
  <v-container class="pa-0" fluid>
    <v-container class="pb-0">
      <!-- header -->
      <header-sheet />
    </v-container>

    <section class="py-12">
      <v-container class="mb-6 my-xl-12 mb-12 mb-md-0">
        <v-row class="align-center">
          <v-col cols="12" md="6" class="pr-md-12">
            <h1 class="text-h4 text-xl-h3 font-weight-black font-italic mb-4">
              SOMOS A ARENA JOGUE FÁCIL
            </h1>

            <p class="text-h6 text-xl-h5 font-weight-regular subtext--text">
              Melhor centro e-Sportivo do Brasil. Oferecemos um espaço moderno
              dedicado ao esporte eletrônico, para aqueles que desejam entrar e
              ter sucesso no mercado de e-Sports.
            </p>

            <p
              class="text-h6 text-xl-h5 font-weight-regular subtext--text mb-0"
            >
              Pensamos em tudo para promover com a mais alta qualidade, cada
              produto digital, são transmissões ao vivo, torneios, campanhas de
              marketing e muito mais. Aqui nosso jogador possui equipamentos da
              mais alta qualidade para se tornar um profissional. Tudo pensado
              para seu entretenimento, paixão e diversão.
            </p>
          </v-col>

          <v-col cols="12" md="6" class="pl-md-12">
            <video-bg
              :sources="[require('@/assets/about/arena-drone.webm')]"
              class="rounded-lg"
              style="max-height: 75vh"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="altback py-12">
      <v-container class="my-6 my-xl-12">
        <v-row class="align-center" no-gutters>
          <v-col cols="12" md="6" class="pr-md-12 mb-12 mb-md-0">
            <v-img
              :src="require('@/assets/about/wedo.jpg')"
              class="rounded-lg"
              :aspect-ratio="16 / 9"
            />
          </v-col>

          <v-col cols="12" md="6" class="pl-md-12">
            <h1 class="text-h4 text-xl-h3 font-weight-black font-italic mb-4">
              O QUE FAZEMOS?
            </h1>

            <p
              class="text-h6 text-xl-h5 font-weight-regular subtext--text mb-0"
            >
              Fornecemos produtos digitais de alta qualidade, como torneios,
              campeonatos, transmissões de jogos ao vivo para todos os que
              desejam se tornar um pro player ou para as empresas que desejam
              entrar e ter sucesso no mercado de e-Sports. Aqui você possui
              equipamentos para se tornar um profissional.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- comments -->
    <section class="py-12">
      <v-container class="my-6 my-xl-12">
        <div class="text-center mb-6">
          <p class="text-h5 text-xl-h4 font-weight-black font-italic">
            Algumas opniões de nossos clientes
          </p>
        </div>

        <v-row justify="center" align="center">
          <!--left-->
          <v-btn color="primary" class="rounded-lg" icon left @click="window--">
            <v-icon large>mdi-chevron-left</v-icon>
          </v-btn>

          <!-- comment -->
          <v-col cols="9" md="8" lg="6" xl="5">
            <v-card color="surface" class="pa-6">
              <v-window v-model="window" class="elevation-0">
                <v-window-item v-for="(item, index) in comments" :key="index">
                  <v-card-text
                    class="
                      text-center text-h6 text-xl-h5
                      font-weight-regular
                      pb-0
                    "
                  >
                    "{{ item.text }}"
                  </v-card-text>

                  <v-card-title
                    class="text-h6 text-xl-h5 font-weight-bold justify-center"
                  >
                    {{ item.author }}
                  </v-card-title>

                  <div align="center">
                    <v-avatar color="gradient">
                      <v-icon color="white" large>mdi-google-controller</v-icon>
                    </v-avatar>
                  </div>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>

          <!--right-->
          <v-btn
            color="primary"
            class="rounded-lg"
            icon
            right
            @click="window++"
          >
            <v-icon large>mdi-chevron-right</v-icon>
          </v-btn>

          <!-- dots -->
          <v-col cols="12">
            <v-item-group v-model="window" class="text-center" mandatory>
              <v-item
                v-for="n in comments.length"
                :key="`btn-${n}`"
                v-slot="{ active, toggle }"
              >
                <v-btn :input-value="active" icon @click="toggle">
                  <v-icon>mdi-record</v-icon>
                </v-btn>
              </v-item>
            </v-item-group>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- cards -->
    <section class="altback py-12">
      <v-container class="my-6 my-xl-12">
        <v-row class="justify-space-around">
          <v-col
            v-for="(item, index) in cards"
            :key="index"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card class="rounded-lg pa-6" color="surface" height="100%">
              <v-responsive :aspect-ratio="16 / 9">
                <div
                  class="
                    d-flex
                    flex-column
                    align-center
                    justify-center
                    fill-height
                  "
                >
                  <v-avatar class="mb-6" color="gradient">
                    <v-icon color="white" large>{{ item.icon }}</v-icon>
                  </v-avatar>

                  <h1 class="text-h5 text-xl-h4 font-weight-bold mb-4">
                    {{ item.title }}
                  </h1>

                  <p
                    class="
                      text-h6 text-xl-h5
                      font-weight-regular
                      subtext--text
                      text-center
                      mb-0
                    "
                  >
                    {{ item.text }}
                  </p>
                </div>
              </v-responsive>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
import VideoBg from "vue-videobg";

export default {
  data() {
    return {
      cards: [
        {
          icon: "mdi-eye",
          title: "Visão",
          text: "Impressionar nossos clientes através dos nossos serviços, garantindo uma agradável e inesquecível experência.",
        },
        {
          icon: "mdi-bullseye-arrow",
          title: "Missão",
          text: "Proporcionar um ambiente agradável e moderno, dedicado ao e-Sport eletrônico com serviços de qualidade melhores tecnologias.",
        },
        {
          icon: "mdi-handshake",
          title: "Valores",
          text: "Compromisso inabalável com transparência, modernidade, respeito, serviços, atendimento e segurança.",
        },
      ],
      comments: [
        {
          author: "Rafaela Cavalcanti ",
          text: "Gente não consigo mais sair da arena, amo jogar, lanchar, me divertir aqui",
        },
        {
          author: "Marcos Castro",
          text: "Estrutura e atendimento incrível, aqui já é minha segunda casa rsrs",
        },
        {
          author: "Fábio Cunha",
          text: "Sinceramente, aqui é a melhor arena do Brasil de verdade, não tem como não amar esse lugar...",
        },
      ],
      window: 0,
    };
  },

  components: {
    VideoBg,
  },
};
</script>
